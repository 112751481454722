<template>
    <div>
        <div class="main-content">
            <el-tabs v-model="navIndex" @tab-click="handleClick" class="mt10" >
                <el-tab-pane label="视频评论" name="0"></el-tab-pane>
                <el-tab-pane label="报告评论" name="1"></el-tab-pane>
            </el-tabs>
            <div class="list" v-for="(item,index) in list" :key="index">
                <div class="item">
                    <div class="item-head">
                        <div class="item-head-img">
                            <img :src="item.headUrl ? item.headUrl : ''" alt="">
                        </div>
                        <div class="item-head-info">
                            <div class="f16 fw700">{{ item.evaluatorsName || '' }} <span class="f16 ml10" v-if="item.evaluatorsId != userInfo.id">回复了</span></div>
                            <div class="f12 aaa mt5">{{ item.releaseTime }}</div>
                        </div>
                        <div class="item-head-del" v-if="item.evaluatorsId == userInfo.id" @click="delEva(item.id,index,list)">
                            删除
                        </div>
                    </div>
                    <div class="comments mt15 f16" v-if="item.evaluatorsId != userInfo.id">
                        {{ item.content || '' }}
                    </div>
                    <div class="item-info mt15">
                        <div class="item-info-myComments f16" v-if="item.evaluatorsId == userInfo.id">
                            <h5>我的评论：</h5>
                            <span class="aaa">{{ item.content || '' }}</span>
                        </div>
                        <div class="item-info-myComments f16" v-if="item.evaluatorsId != userInfo.id">
                            <h5>我的评论：</h5>
                            <span class="aaa">{{ item.replyContent || '' }}</span>
                        </div>
                        <div class="item-info-detail mt5" v-if="item.objType == 1" @click="link_detail(item.video.id,item.video.type,item.video.vipSet)">
                            <img :src="item.video && item.video.photoUrl ? item.video.photoUrl : ''" alt="">
                            <div class=" fff">
                                <span class="f16 ml15">{{ item.video.videoTitle || '' }}</span>
                            </div>
                        </div>
                        <div class="item-info-detail mt5" v-if="item.objType == 2" @click="link_detail2(item.report.videoId)">
                            <img :src="item.report && item.report.photoUrl ? item.report.photoUrl : ''" alt="">
                            <div class=" fff">
                                <span class="f16 ml15">{{ item.report.reportText || '' }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <nodata v-if="list.length<=0"></nodata>
        </div>
        <gap height="50"></gap>
            <Homefooter ></Homefooter>
    </div>
</template>

<script>
    import {
		myVideoList,videoCommentsDel,selectByIdBase2
	} from '@/apiUrl/index.js'
	import {
		mapGetters
	} from 'vuex'
    export default {
        computed:{
			...mapGetters(['userInfo'])
		},
        data() {
            return {
                current: 1,
				isOK: true,
				list:[],
                total:100,
                navIndex:0
            }
        },
        created() {
            this.getdata();
        },
        methods: {
            // 删除评论
            delEva(id,index,list) {
                this.$confirm('你确定要删除该评论吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                    }).then(() => {
                        videoCommentsDel({
                            id
                        }).then(res =>{
                            if(res.code == 200) {
                                this.$msg('删除成功！')
                                list.splice(index,1);
                            } else {
                                this.$msg(res.message)
                            }
                        })
                })
			},
            currentChange(val) {
                this.current = val;
                this.getdata();
            },
			getdata() {
				let form = {
					"size": 999,
					"current": this.current,
				}
                console.log(this.navIndex)
                if(this.navIndex == 0) {
                    form.type = 'video';
                    myVideoList(form).then(res => {
                        console.log('res', res);
                        if (res.code == '200') {
                            var arr = res.data;
                            this.list = arr || [];
                        }
                    })
                } else if(this.navIndex == 1) {
                    form.type = 'report';
                    myVideoList(form).then(res => {
                        console.log('res', res);
                        if (res.code == '200') {
                            var arr  = res.data;
                            this.list = arr || [];
                        }
                    })
                }
				
			},
            handleClick(tab, event) {
                this.current = 1;
                this.getdata();
            },
            link_detail2(id){
				selectByIdBase2({id}).then(res => {
					if(res.code == 200) {
						let obj = res.data;
						if(obj.type==1){
                            this.$link('/video_detail?id=' + id + '&mode=video')
						}else if(obj.type==2){
							if(obj.vipSet==2){
								this.$link('/video_detail2?id=' + id + '&mode=video')
							}else{
								this.$link('/video_detail1?id=' + id + '&mode=video')
							}
						}
					}
				})
			},
			link_detail(id,type,vipSet){
                if(type==1){
                    this.$link('/video_detail?id=' + id + '&mode=video')
				}else if(type==2){
					if(vipSet==2){
                        this.$link('/video_detail2?id=' + id + '&mode=video')
					}else{
						this.$link('/video_detail1?id=' + id + '&mode=video')
					}
				}
			}
		},
    }
</script>

<style lang="less" scoped>
.aaa {
    color: #aaa;
}
.fff {
    background-color: #fff;
}
.main-content {
    width: 100%;
    background-color: #fff;
    padding:15px;
    .h1 {
        height: 48px;
        line-height: 48px;
        font-size: 18px;
        font-weight: 600;
        color: #2e2e2e;
        padding-left: 16px;
        border-bottom: 1px solid #f0f0f2;
    }
}
.list {
    padding: 15px;
    border-bottom: 1px solid #f0f0f2;
    .item {
        .item-head {
            display: flex;
            // justify-content: space-between;
            align-items: center;
            .item-head-img {
                width: 42px;
                height: 42px;
                border-radius: 50%;
                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                }
            }
            .item-head-info {
                margin-left: 15px;
                flex: 1;
            }
        }
        .item-info {
            padding: 15px;
            background-color: #f5f6f8;
            border-radius: 4px;
            overflow: hidden;
            .item-info-myComments {
                display: flex;
                align-items: center;
            }
            .item-info-detail {
                display: flex;
                align-items: center;
                border-radius: 4px;
                overflow: hidden;
                img{
                    width: 120px;
                    height: 80px;
                }
                div {
                    flex:1;
                    height: 80px;
                    line-height: 80px;
                }
            }
        }
    }
}
.list:last-child {
    border-bottom: 0;
}
.item-head-del {
    float: right;
    border: 1px solid var(--color);
    border-radius: 20px;
    padding: 3px 12px;
    font-size: 12px;
    color: var(--color);
}

</style>